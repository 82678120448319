import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigation-buttons',
  templateUrl: './navigation-buttons.component.html',
  styleUrls: ['./navigation-buttons.component.scss'],
  standalone: false,
})
export class NavigationButtonsComponent implements OnInit {
  public isGoBackDisabled: boolean;
  public isGoForwardDisabled: boolean;

  private readonly mixingPath: string;

  constructor(private location: Location) {
    this.isGoBackDisabled = false;
    this.isGoForwardDisabled = false;
    this.mixingPath = '/mixes/mixing';
  }

  public ngOnInit(): void {
    this.updateButtonsDisableState(this.location.path());
    this.initUrlChangeListener();
  }

  public goBack(): void {
    this.location.back();
  }

  public goForward(): void {
    this.location.forward();
  }

  private updateButtonsDisableState(path: string): void {
    this.updateIsGoBackDisabled(path);
    this.updateIsGoForwardDisabled(path);
  }

  private initUrlChangeListener(): void {
    this.location.onUrlChange((url: string) => this.updateButtonsDisableState(url));
  }

  private updateIsGoBackDisabled(path: string): void {
    const isMixingPage: boolean = this.getIsMixingPage(path);

    if (isMixingPage) {
      this.isGoBackDisabled = true;

      return;
    }

    this.isGoBackDisabled = false;
  }

  private updateIsGoForwardDisabled(path: string): void {
    const isMixingPage: boolean = this.getIsMixingPage(path);

    if (isMixingPage) {
      this.isGoForwardDisabled = true;

      return;
    }

    this.isGoForwardDisabled = false;
  }

  private getIsMixingPage(path: string): boolean {
    return path === this.mixingPath;
  }
}
